import React, { Component } from "react";
import axios from "axios";
//import env from './.env';
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_amount: 100,
      refund_id: 0,
      paymentData : {}
    };
  }

  async componentDidMount() {
    const headers = {
      "Content-Type": "application/json"
    };

    console.log(this.props.match.params.payment_id);
    let x =  await axios.post(
        "https://plunes.co/v4/payment",
        {
          payment_id: this.props.match.params.payment_id
        },
        {
          headers : headers
        }
      )
    console.log(x, 'data')
      this.setState({paymentData : x.data.data});
    const { paymentAmount } = this.state.paymentData;
    const self = this;
    const options = {
      key: 'rzp_live_0BlwkbGVy4d5lU', //process.env.REACT_APP_RAZOR_PAY_TEST_KEY,
      amount: parseInt(paymentAmount) * 100,
      name: "Payments",
      description: "",
      redirect: true,
      callback_url: "https://plunes.co/v4/payment/capture/"+ this.state.paymentData.bookingId,
      handler(response) {
        const paymentId = response.razorpay_payment_id;
        const url =
          "https://plunes.co/v4" +
          "/payment/capture/" +
          paymentId +
          "/" +
          paymentAmount;
        // Using my server endpoints to capture the payment
        fetch(url, {
          method: "get",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          }
        })
          .then(resp => resp.json())
          .then(function(data) {
            console.log("Request succeeded with JSON response", data);
            self.setState({
              refund_id: response.razorpay_payment_id
            });
          })
          .catch(function(error) {
            console.log("Request failed", error);
          });
      },
      prefill: {
        contact: this.state.paymentData.phone_number,
        email: this.state.paymentData.email
      },
      theme: {
        color: "#01D35A"
      },
      modal: {
        ondismiss: () => {
          window.location.replace("https://plunes.co/v4/payment/cancelled/"+ this.props.match.params.payment_id );
        }
      }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  render() {
    console.log(this.state);
    const { payment_amount, refund_id } = this.state;
    return <div />;
  }
}

export default Checkout;
