import React, { useState } from "react";
import Loading from './Loading';



function LoadingFailure() {

  return (
    <Loading/>
  );
}

export default LoadingFailure;