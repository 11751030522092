import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Checkout from './Checkout';
import LoadingSuccess from './LoadingSuccess';
import LoadingFailure from './LoadingFailure';
import LoadingCancelled from './LoadingCancelled';
// require('dotenv').config();

const NoMatchPage = () => {
  return <h3>404 - Not found</h3>;
};

const App = () => {
  return (
    <section className="App">
      <Router>
        <Switch>
          <Route exact path="/payment/:payment_id" component={Checkout} />
          <Route exact path="/payment/success/:booking_id" component={LoadingSuccess} />
          <Route exact path="/payment/error" component={LoadingFailure}/>
          <Route exact path="/payment/cancelled/:payment_id" component={LoadingCancelled}/>
          <Route  component={NoMatchPage} />
        </Switch>
      </Router>
    </section>
  );
};

const rootElement = document.getElementById("root");
console.log(rootElement);
ReactDOM.render(<App />, rootElement);

