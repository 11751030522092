import React, { useState } from "react";
import Loading from './Loading';



function LoadingSuccess() {

  return (
    <Loading/>
  );
}

export default LoadingSuccess;


